@font-face {
  font-family: GrotaSans;
  src: url("/Vendor/grotasans/Latinotype - GrotaSansAltBook.otf") format("opentype"); }

@font-face {
  font-family: GrotaSans;
  src: url("/Vendor/grotasans/Latinotype - GrotaSansRd-ExtraBold.otf") format("opentype");
  font-weight: 700; }

/*#afd186*/
@font-face {
  font-family: GrotaSans;
  src: url("/Vendor/grotasans/Latinotype - GrotaSansAltBook.otf") format("opentype"); }

@font-face {
  font-family: GrotaSans;
  src: url("/Vendor/grotasans/Latinotype - GrotaSansRd-ExtraBold.otf") format("opentype");
  font-weight: 700; }

/*#afd186*/
header.main-header {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  background-color: #FFF;
  min-height: 76px;
  height: 100px;
  position: fixed;
  width: 100%;
  z-index: 1000;
  top: 0;
  transition: ease 0.2s; }
  header.main-header.section {
    background-color: #FFF; }
  header.main-header.start, header.main-header.news {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    color: #FFF !important; }
    header.main-header.start a, header.main-header.start button, header.main-header.news a, header.main-header.news button {
      color: #FFF !important; }
  header.main-header.active {
    background: #FFF;
    color: #00234A !important; }
    header.main-header.active a, header.main-header.active button {
      color: #00234A !important; }
  header.main-header .navbar {
    max-width: 1920px;
    margin: 0 auto;
    padding: 0;
    width: 100%; }
  header.main-header .navbar-collapse {
    align-items: stretch;
    padding: 0; }
  header.main-header .navbar-brand {
    display: flex;
    align-items: center;
    padding: 0 1rem;
    position: relative; }
    header.main-header .navbar-brand .mobile-img, header.main-header .navbar-brand .mobile-img-wide {
      display: none !important; }
    header.main-header .navbar-brand .desktop-img-wide {
      position: absolute; }
  header.main-header a {
    color: #00234A;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px; }
    header.main-header a:hover {
      text-decoration: underline; }
  header.main-header .mobile-search {
    display: none; }
  header.main-header .mobile-cart {
    display: none; }
  header.main-header .navbar-toggler {
    color: #FFF;
    margin-right: 12px; }
    header.main-header .navbar-toggler:hover, header.main-header .navbar-toggler:focus, header.main-header .navbar-toggler:active {
      background-color: #FFF; }
    header.main-header .navbar-toggler.collapsed .navbar-toggler-icon {
      background: url(/Static/icons/AH/closed.svg) no-repeat center; }
  header.main-header .navbar-toggler-icon {
    background: url(/Static/icons/AH/open.svg) no-repeat center;
    background-size: contain; }
  header.main-header .nav-item {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    position: relative; }
    header.main-header .nav-item::before {
      border: 0; }
    header.main-header .nav-item.current-page {
      text-decoration: underline; }
    header.main-header .nav-item .collapse {
      background: #FFF;
      position: absolute !important;
      top: 84px;
      padding-left: 0;
      list-style: none;
      box-shadow: 2px 2px 14px rgba(0, 35, 74, 0.1);
      z-index: 1004; }
      header.main-header .nav-item .collapse .menuitem {
        border-bottom: 1px solid #DCDCE5;
        min-width: 200px; }
      header.main-header .nav-item .collapse .mobile-close, header.main-header .nav-item .collapse .mobile-item {
        display: none; }
      header.main-header .nav-item .collapse .nav-item:last-child .menuitem {
        border-bottom: 0; }
      header.main-header .nav-item .collapse:before {
        content: "";
        position: absolute;
        height: 12px;
        width: 12px;
        background-color: #FFF;
        top: -5px;
        transform: rotate(45deg);
        left: 16%; }
      header.main-header .nav-item .collapse .current-page .nav-link {
        font-weight: 500;
        text-decoration: none; }
      header.main-header .nav-item .collapse .nav-link {
        border-top: 0;
        border-left: 4px solid transparent;
        color: #00234A !important;
        font-weight: 400;
        line-height: 28px;
        padding: 0 16px 0 12px;
        z-index: 1;
        transition: 0.1s;
        flex-grow: 1; }
        header.main-header .nav-item .collapse .nav-link:hover, header.main-header .nav-item .collapse .nav-link:focus {
          border-color: #00234A;
          color: #00234A; }
  header.main-header .nav-link {
    display: flex;
    align-items: center;
    padding: 1rem; }
    header.main-header .nav-link:hover {
      border-color: #FFF; }
  header.main-header .current-page .nav-link {
    border-color: #FFF; }
  header.main-header .menuitem {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 0; }
    header.main-header .menuitem .nav-link {
      display: flex;
      align-items: center; }
  header.main-header .collapse-toggle, header.main-header .language-toggle {
    color: #00234A;
    background: transparent;
    width: 24px;
    border: 0;
    cursor: pointer;
    display: flex;
    align-items: stretch;
    justify-content: flex-end; }
  header.main-header .desktop-search {
    display: flex;
    align-items: center; }
    header.main-header .desktop-search::before {
      border: 0; }
  header.main-header .toggle-search {
    border: 0;
    height: 50px;
    width: 50px;
    background: transparent;
    margin-right: 24px;
    margin-left: 12px;
    padding: 0 30px;
    cursor: pointer; }
  header.main-header .search-field {
    display: none;
    position: fixed;
    top: 76px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00234A;
    z-index: 1002; }
    header.main-header .search-field .container {
      height: 100%; }
      header.main-header .search-field .container .row {
        height: 100%;
        display: flex;
        justify-content: center; }
        header.main-header .search-field .container .row form {
          position: relative;
          margin-top: 12%; }
  header.main-header .login-nav {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 0;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0 !important; }
    header.main-header .login-nav li {
      display: flex;
      align-items: stretch; }
      header.main-header .login-nav li::before {
        display: none; }
    header.main-header .login-nav a {
      padding: 0.1rem 0.5rem; }
  @media only screen and (max-width: 991px) {
    header.main-header .login-nav {
      align-items: flex-start;
      border-top: 1px solid #DCDCE5;
      border-bottom: 1px solid #DCDCE5; }
      header.main-header .login-nav a {
        padding: 1rem !important; }
    header.main-header header.main-header .search-nav {
      display: none; } }
  header.main-header .cart-container > div {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
  header.main-header #languages {
    margin-right: 36px; }
    header.main-header #languages .icon-frame {
      padding-left: 8px; }
    header.main-header #languages .collapse {
      top: 84px;
      right: 0;
      min-width: 200px;
      border-radius: 4px !important;
      z-index: 1004; }
      header.main-header #languages .collapse::before {
        left: 88% !important; }
      header.main-header #languages .collapse .nav-item {
        border-bottom: 1px solid #DCDCE5;
        padding: 8px 0; }
        header.main-header #languages .collapse .nav-item:last-child {
          border-bottom: 0; }
        header.main-header #languages .collapse .nav-item .nav-link {
          padding-left: 40px;
          background-repeat: no-repeat;
          background-position: 8px 50%;
          background-size: 26px;
          text-transform: capitalize; }
          header.main-header #languages .collapse .nav-item .nav-link.fi {
            background-image: url(/Static/icons/flag/circleflags/fi.svg); }
          header.main-header #languages .collapse .nav-item .nav-link.cs {
            background-image: url(/Static/icons/flag/circleflags/cz.svg); }
          header.main-header #languages .collapse .nav-item .nav-link.da {
            background-image: url(/Static/icons/flag/circleflags/dk.svg); }
          header.main-header #languages .collapse .nav-item .nav-link.hu {
            background-image: url(/Static/icons/flag/circleflags/hu.svg); }
          header.main-header #languages .collapse .nav-item .nav-link.no {
            background-image: url(/Static/icons/flag/circleflags/no.svg); }
          header.main-header #languages .collapse .nav-item .nav-link.pl {
            background-image: url(/Static/icons/flag/circleflags/pl.svg); }
          header.main-header #languages .collapse .nav-item .nav-link.ro {
            background-image: url(/Static/icons/flag/circleflags/ro.svg); }
          header.main-header #languages .collapse .nav-item .nav-link.ru {
            background-image: url(/Static/icons/flag/circleflags/ru.svg); }
          header.main-header #languages .collapse .nav-item .nav-link.sv {
            background-image: url(/Static/icons/flag/circleflags/se.svg); }
          header.main-header #languages .collapse .nav-item .nav-link.sl {
            background-image: url(/Static/icons/flag/circleflags/si.svg); }
          header.main-header #languages .collapse .nav-item .nav-link.sk {
            background-image: url(/Static/icons/flag/circleflags/sk.svg); }
          header.main-header #languages .collapse .nav-item .nav-link.en {
            background-image: url(/Static/icons/flag/global.svg); }
          header.main-header #languages .collapse .nav-item .nav-link.et {
            background-image: url(/Static/icons/flag/circleflags/ee.svg); }
          header.main-header #languages .collapse .nav-item .nav-link.lt {
            background-image: url(/Static/icons/flag/circleflags/lt.svg); }
          header.main-header #languages .collapse .nav-item .nav-link.lv {
            background-image: url(/Static/icons/flag/circleflags/lv.svg); }
          header.main-header #languages .collapse .nav-item .nav-link.uk {
            background-image: url(/Static/icons/flag/circleflags/ua.svg); }
          header.main-header #languages .collapse .nav-item .nav-link.ka {
            background-image: url(/Static/icons/flag/circleflags/ge.svg); }
          header.main-header #languages .collapse .nav-item .nav-link.fr {
            background-image: url(/Static/icons/flag/circleflags/fr.svg); }
          header.main-header #languages .collapse .nav-item .nav-link.nl {
            background-image: url(/Static/icons/flag/circleflags/nl.svg); }
          header.main-header #languages .collapse .nav-item .nav-link.nl-be {
            background-image: url(/Static/icons/flag/circleflags/be.svg); }
          header.main-header #languages .collapse .nav-item .nav-link.fr-be {
            background-image: url(/Static/icons/flag/circleflags/be.svg); }
          header.main-header #languages .collapse .nav-item .nav-link.de-lu {
            background-image: url(/Static/icons/flag/circleflags/lu.svg); }
          header.main-header #languages .collapse .nav-item .nav-link.fr-lu {
            background-image: url(/Static/icons/flag/circleflags/lu.svg); }
  header.main-header .language-toggle {
    align-items: center;
    background-repeat: no-repeat;
    background-position: 8px 50%;
    background-size: 26px;
    font-weight: 500;
    padding-left: 40px;
    width: auto;
    padding-top: 0;
    padding-bottom: 0;
    border-top: 1px solid #DCDCE5 !important; }
    header.main-header .language-toggle.fi {
      background-image: url(/Static/icons/flag/circleflags/fi.svg); }
    header.main-header .language-toggle.cs {
      background-image: url(/Static/icons/flag/circleflags/cz.svg); }
    header.main-header .language-toggle.da {
      background-image: url(/Static/icons/flag/circleflags/dk.svg); }
    header.main-header .language-toggle.hu {
      background-image: url(/Static/icons/flag/circleflags/hu.svg); }
    header.main-header .language-toggle.no {
      background-image: url(/Static/icons/flag/circleflags/no.svg); }
    header.main-header .language-toggle.pl {
      background-image: url(/Static/icons/flag/circleflags/pl.svg); }
    header.main-header .language-toggle.nl-be {
      background-image: url(/Static/icons/flag/circleflags/be.svg); }
    header.main-header .language-toggle.fr-be {
      background-image: url(/Static/icons/flag/circleflags/be.svg); }
    header.main-header .language-toggle.ro {
      background-image: url(/Static/icons/flag/circleflags/ro.svg); }
    header.main-header .language-toggle.ru {
      background-image: url(/Static/icons/flag/circleflags/ru.svg); }
    header.main-header .language-toggle.sv {
      background-image: url(/Static/icons/flag/circleflags/se.svg); }
    header.main-header .language-toggle.sl {
      background-image: url(/Static/icons/flag/circleflags/si.svg); }
    header.main-header .language-toggle.sk {
      background-image: url(/Static/icons/flag/circleflags/sk.svg); }
    header.main-header .language-toggle.en {
      background-image: url(/Static/icons/flag/global.svg); }
    header.main-header .language-toggle.et {
      background-image: url(/Static/icons/flag/circleflags/ee.svg); }
    header.main-header .language-toggle.lt {
      background-image: url(/Static/icons/flag/circleflags/lt.svg); }
    header.main-header .language-toggle.lv {
      background-image: url(/Static/icons/flag/circleflags/lv.svg); }
    header.main-header .language-toggle.fr {
      background-image: url(/Static/icons/flag/circleflags/fr.svg); }
  header.main-header.start .language-toggle.en, header.main-header.news .language-toggle.en {
    background-image: url(/Static/icons/flag/global-white.svg); }
  header.main-header.start.active #languages .nav-item .nav-link.en, header.main-header.news.active #languages .nav-item .nav-link.en {
    background-image: url(/Static/icons/flag/global.svg); }
  header.main-header.start.active .language-toggle.en, header.main-header.news.active .language-toggle.en {
    background-image: url(/Static/icons/flag/global.svg); }

.language-extra {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 8px 0 !important;
  margin: 0; }
  .language-extra li {
    display: flex;
    flex-direction: row;
    align-items: center; }
    .language-extra li::before {
      display: none; }

@media only screen and (max-width: 1600px) {
  header.main-header .search-nav {
    padding-left: 0; } }

@media only screen and (min-width: 992px) {
  header.main-header .navbar-brand {
    width: 201px; }
    header.main-header .navbar-brand .desktop-img {
      width: 169px;
      height: 100px; }
    header.main-header .navbar-brand .desktop-img-wide {
      width: 169px;
      height: auto;
      top: 0; } }

@media only screen and (max-width: 991px) {
  header.main-header {
    background: #FFF !important;
    height: 64px;
    min-height: 64px; }
    header.main-header .navbar-brand {
      padding-left: 0; }
      header.main-header .navbar-brand .desktop-img, header.main-header .navbar-brand .desktop-img-wide {
        display: none !important; }
      header.main-header .navbar-brand .mobile-img, header.main-header .navbar-brand .mobile-img-wide {
        display: block !important; }
      header.main-header .navbar-brand img {
        height: 64px; }
      header.main-header .navbar-brand .mobile-img-wide {
        top: 0;
        height: 70px;
        width: auto; }
    header.main-header .right-content {
      display: flex;
      flex-direction: row;
      height: 64px; }
    header.main-header > .navbar {
      border-bottom: 1px solid #99B9D9;
      height: 64px; }
    header.main-header.start, header.main-header.news {
      background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
      color: #FFF !important; }
      header.main-header.start a, header.main-header.start button, header.main-header.news a, header.main-header.news button {
        color: #00234A !important; }
    header.main-header .desktop-search {
      display: none; }
    header.main-header .mobile-search {
      display: flex;
      align-items: center;
      position: relative; }
      header.main-header .mobile-search .toggle-search {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0; }
        header.main-header .mobile-search .toggle-search svg {
          width: 32px;
          height: 32px; }
      header.main-header .mobile-search .sb-search-input {
        font-size: 18px;
        height: 52px;
        padding: 0 40px 0 16px; }
      header.main-header .mobile-search .sb-search-submit {
        border: 0;
        padding: 0 30px; }
    header.main-header .mobile-cart {
      display: flex;
      align-items: center; }
      header.main-header .mobile-cart .mobile-cart-btn {
        background: transparent;
        display: flex;
        justify-content: center;
        align-items: center; }
        header.main-header .mobile-cart .mobile-cart-btn img {
          width: 30px; }
    header.main-header .navbar-collapse.show {
      position: fixed;
      background-color: #FFF;
      top: 64px;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      overflow-y: auto;
      z-index: 1000; }
    header.main-header .navbar-collapse .navbar-nav {
      background-color: #FFF; }
      header.main-header .navbar-collapse .navbar-nav .menuitem {
        align-items: stretch;
        justify-content: space-between;
        flex-grow: 1;
        flex-shrink: 0;
        padding: 0;
        border: 0; }
      header.main-header .navbar-collapse .navbar-nav .nav-item {
        border-bottom: 1px solid #DCDCE5;
        flex-direction: column;
        margin: 0; }
        header.main-header .navbar-collapse .navbar-nav .nav-item:first-child {
          border-top: 1px solid #DCDCE5; }
        header.main-header .navbar-collapse .navbar-nav .nav-item:last-of-type {
          border-bottom: 0; }
        header.main-header .navbar-collapse .navbar-nav .nav-item.mobile-title .nav-link {
          color: #E0F3FE;
          font-size: 26px;
          line-height: 36px;
          font-weight: 700;
          border-bottom: 0; }
        header.main-header .navbar-collapse .navbar-nav .nav-item .nav-link {
          border-top: 0px;
          display: block;
          position: relative; }
        header.main-header .navbar-collapse .navbar-nav .nav-item .collapse-toggle, header.main-header .navbar-collapse .navbar-nav .nav-item .language-toggle {
          display: block;
          border-color: #DCDCE5;
          border-width: 0 0 0 1px;
          border-style: solid;
          width: 60px; }
          header.main-header .navbar-collapse .navbar-nav .nav-item .collapse-toggle .icon, header.main-header .navbar-collapse .navbar-nav .nav-item .language-toggle .icon {
            display: block;
            transition: 0.2s; }
          header.main-header .navbar-collapse .navbar-nav .nav-item .collapse-toggle.open .icon, header.main-header .navbar-collapse .navbar-nav .nav-item .language-toggle.open .icon {
            transform: rotate(180deg); }
        header.main-header .navbar-collapse .navbar-nav .nav-item .collapse {
          position: static !important;
          top: 72px;
          transition: 0.3s;
          z-index: 1004; }
          header.main-header .navbar-collapse .navbar-nav .nav-item .collapse.show {
            display: block !important; }
          header.main-header .navbar-collapse .navbar-nav .nav-item .collapse::before {
            display: none; }
          header.main-header .navbar-collapse .navbar-nav .nav-item .collapse .menuitem {
            background-color: #F6F6F8;
            padding-top: 8px;
            padding-bottom: 8px; }
          header.main-header .navbar-collapse .navbar-nav .nav-item .collapse .mobile-close, header.main-header .navbar-collapse .navbar-nav .nav-item .collapse .mobile-item {
            display: block; }
    header.main-header #languages {
      margin: 0; }
      header.main-header #languages .nav-item {
        border-top: 0;
        flex-grow: 1;
        flex-shrink: 0; }
        header.main-header #languages .nav-item .language-toggle.en, header.main-header #languages .nav-item .nav-link.en {
          background-image: url(/Static/icons/flag/global.svg) !important; }
        header.main-header #languages .nav-item button {
          border-bottom: 1px solid #DCDCE5;
          border-left: 0;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: stretch;
          padding-right: 0;
          width: 100%; }
          header.main-header #languages .nav-item button::after {
            display: none; }
          header.main-header #languages .nav-item button .text {
            padding: 1rem 0; }
          header.main-header #languages .nav-item button .icon-frame {
            border-left: 1px solid #DCDCE5;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 0;
            width: 60px; }
        header.main-header #languages .nav-item .collapse .nav-item {
          background-color: #F6F6F8; }
  .language-extra {
    padding: 0 !important;
    border-top: 1px solid #DCDCE5;
    justify-content: flex-start; }
    .language-extra li {
      margin: 0; }
    .language-extra span {
      color: #00234A !important; } }

#language-dialog, .language-popup-dialog {
  padding-top: 0 !important; }
  #language-dialog.ui-dialog.language-dialog .ui-dialog-title, .language-popup-dialog.ui-dialog.language-dialog .ui-dialog-title {
    color: #00234A !important; }
  #language-dialog ul, .language-popup-dialog ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
    margin: 0 0 16px 0; }
    #language-dialog ul li, .language-popup-dialog ul li {
      padding: 12px;
      width: 162px; }
      #language-dialog ul li::before, .language-popup-dialog ul li::before {
        display: none; }
      #language-dialog ul li a, .language-popup-dialog ul li a {
        background-repeat: no-repeat;
        background-position: 5% 50%;
        border-left: 4px solid transparent;
        padding: 2px 0 2px 36px;
        transition-delay: 0.1s;
        background-size: 26px; }
        #language-dialog ul li a:hover, #language-dialog ul li a:focus, .language-popup-dialog ul li a:hover, .language-popup-dialog ul li a:focus {
          color: #00234A;
          font-weight: 600;
          border-color: #00234A; }
        #language-dialog ul li a.en, .language-popup-dialog ul li a.en {
          background-image: url(/Static/icons/flag/global.svg); }
        #language-dialog ul li a.fi, .language-popup-dialog ul li a.fi {
          background-image: url(/Static/icons/flag/fi.svg); }
        #language-dialog ul li a.ru, .language-popup-dialog ul li a.ru {
          background-image: url(/Static/icons/flag/ru.svg); }
        #language-dialog ul li a.ro, .language-popup-dialog ul li a.ro {
          background-image: url(/Static/icons/flag/ro.svg); }
        #language-dialog ul li a.no, .language-popup-dialog ul li a.no {
          background-image: url(/Static/icons/flag/no.svg); }
        #language-dialog ul li a.pl, .language-popup-dialog ul li a.pl {
          background-image: url(/Static/icons/flag/pl.svg); }
        #language-dialog ul li a.sl, .language-popup-dialog ul li a.sl {
          background-image: url(/Static/icons/flag/si.svg); }
        #language-dialog ul li a.sk, .language-popup-dialog ul li a.sk {
          background-image: url(/Static/icons/flag/sk.svg); }
        #language-dialog ul li a.da, .language-popup-dialog ul li a.da {
          background-image: url(/Static/icons/flag/dk.svg); }
        #language-dialog ul li a.hu, .language-popup-dialog ul li a.hu {
          background-image: url(/Static/icons/flag/hu.svg); }
        #language-dialog ul li a.cs, .language-popup-dialog ul li a.cs {
          background-image: url(/Static/icons/flag/cz.svg); }
        #language-dialog ul li a.sv, .language-popup-dialog ul li a.sv {
          background-image: url(/Static/icons/flag/se.svg); }
        #language-dialog ul li a.lt, .language-popup-dialog ul li a.lt {
          background-image: url(/Static/icons/flag/lt.svg); }
        #language-dialog ul li a.lv, .language-popup-dialog ul li a.lv {
          background-image: url(/Static/icons/flag/lv.svg); }
        #language-dialog ul li a.et, .language-popup-dialog ul li a.et {
          background-image: url(/Static/icons/flag/et.svg); }
        #language-dialog ul li a.nl-be, .language-popup-dialog ul li a.nl-be {
          background-image: url(/Static/icons/flag/nl-be.svg); }
        #language-dialog ul li a.fr-be, .language-popup-dialog ul li a.fr-be {
          background-image: url(/Static/icons/flag/nl-be.svg); }

@media only screen and (max-width: 575px) {
  header.main-header .toggle-search {
    margin-left: 0;
    margin-right: 8px; }
  header.main-header .navbar-brand .mobile-img-wide {
    height: 68px;
    width: auto; } }

@font-face {
  font-family: GrotaSans;
  src: url("/Vendor/grotasans/Latinotype - GrotaSansAltBook.otf") format("opentype"); }

@font-face {
  font-family: GrotaSans;
  src: url("/Vendor/grotasans/Latinotype - GrotaSansRd-ExtraBold.otf") format("opentype");
  font-weight: 700; }

/*#afd186*/
.EPiServerForms .Form__Element__Caption {
  display: block;
  font-weight: 600; }

.EPiServerForms input, .EPiServerForms textarea {
  max-width: 768px !important;
  padding: 0px 8px; }

.EPiServerForms input[type="checkbox"], .EPiServerForms input[type="radio"] {
  margin-right: 8px;
  width: auto; }

.EPiServerForms fieldset label {
  display: flex;
  flex-direction: row;
  align-items: center; }

.EPiServerForms .Form__Element__ValidationError {
  color: #e60000 !important; }

.EPiServerForms .Form__Status__Message {
  margin: 0; }

.EPiServerForms .FormSubmitButton {
  background-color: #FFAA64;
  border: 0;
  border-radius: .25rem;
  color: #00234A;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  padding: 13px 18px;
  text-shadow: none;
  cursor: pointer;
  transition: 0.1s; }
  .EPiServerForms .FormSubmitButton:hover, .EPiServerForms .FormSubmitButton:focus {
    background-color: #FA811D; }

.form-group label {
  font-weight: 600; }

form input {
  max-width: 768px; }

form .btn {
  margin-top: 16px; }

#my-profile-page .btn {
  margin-top: 16px; }

.form-control {
  max-width: 768px !important; }

@media only screen and (max-width: 767px) {
  .EPiServerForms .Form__MainBody .Form__Element {
    margin: 0 0 1rem 0; }
    .EPiServerForms .Form__MainBody .Form__Element .Form__Element__Caption {
      display: block; } }

@font-face {
  font-family: GrotaSans;
  src: url("/Vendor/grotasans/Latinotype - GrotaSansAltBook.otf") format("opentype"); }

@font-face {
  font-family: GrotaSans;
  src: url("/Vendor/grotasans/Latinotype - GrotaSansRd-ExtraBold.otf") format("opentype");
  font-weight: 700; }

/*#afd186*/
.main-footer {
  background-color: #F6F6F8;
  border-top: 1px solid #DCDCE5;
  font-size: 16px;
  line-height: 24px;
  padding-top: 32px; }
  .main-footer .row {
    margin-top: 0;
    margin-bottom: 0; }
  .main-footer ul {
    list-style: none;
    padding: 0 12px; }
    .main-footer ul:first-child {
      padding-left: 0; }
    .main-footer ul li {
      padding: 4px 0; }
      .main-footer ul li::before {
        border: 0; }
  .main-footer #footer-top {
    padding-bottom: 24px; }
    .main-footer #footer-top .footer-container {
      max-width: 100%;
      padding: 0 60px;
      display: flex;
      justify-content: space-between; }
      .main-footer #footer-top .footer-container.link-area {
        display: flex;
        justify-self: flex-start; }
    .main-footer #footer-top div[class^=col] {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap; }
    .main-footer #footer-top .logo-area {
      justify-content: flex-end;
      max-width: 240px; }
      .main-footer #footer-top .logo-area img {
        max-width: 100%; }
  .main-footer #footer-bottom .footer-container {
    max-width: 100%;
    margin: 0 60px;
    padding: 24px 0;
    border-top: 1px solid #DCDCE5; }
    .main-footer #footer-bottom .footer-container p {
      font-size: 16px;
      line-height: 24px; }
  .main-footer #footer-bottom ul {
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    .main-footer #footer-bottom ul li {
      padding: 0 4px; }
      .main-footer #footer-bottom ul li a {
        text-decoration: underline;
        font-size: 16px; }
        .main-footer #footer-bottom ul li a:hover, .main-footer #footer-bottom ul li a:focus {
          text-decoration: none; }
      .main-footer #footer-bottom ul li:first-child {
        padding-left: 0; }

@media only screen and (max-width: 767px) {
  .main-footer ul {
    padding-left: 0; }
  .main-footer #footer-top .footer-container {
    padding: 0 12px; }
  .main-footer #footer-top div[class^=col] {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap; }
    .main-footer #footer-top div[class^=col].logo-area {
      justify-content: flex-start; }
  .main-footer #footer-bottom .footer-container {
    margin: 0 26px; }
    .main-footer #footer-bottom .footer-container li {
      padding-left: 0 !important; } }

@font-face {
  font-family: GrotaSans;
  src: url("/Vendor/grotasans/Latinotype - GrotaSansAltBook.otf") format("opentype"); }

@font-face {
  font-family: GrotaSans;
  src: url("/Vendor/grotasans/Latinotype - GrotaSansRd-ExtraBold.otf") format("opentype");
  font-weight: 700; }

/*#afd186*/
.hero-lift-container {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 80vh;
  color: #FFF;
  text-shadow: 1px 1px rgba(0, 35, 74, 0.2); }
  .hero-lift-container .row {
    margin-top: 0;
    margin-bottom: 0; }
  .hero-lift-container h1 {
    display: inline-block;
    color: #FFF;
    margin-bottom: 16px;
    padding: 0px 4px 8px 4px; }
  .hero-lift-container p {
    display: inline-block;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 24px;
    padding: 0px 4px 4px 4px; }
  .hero-lift-container .container {
    height: 100%; }
    .hero-lift-container .container .row {
      height: 100%;
      align-items: flex-end; }
  .hero-lift-container .hero-content {
    margin-bottom: 48px; }

.top-content, .middle-content, .bottom-content {
  padding: 40px 0; }

@media only screen and (max-width: 991px) {
  .hero-lift-container p {
    font-size: 16px;
    line-height: 24px; } }

@media only screen and (max-width: 767px) {
  .top-content, .middle-content, .bottom-content {
    padding: 20px 0; } }

@font-face {
  font-family: GrotaSans;
  src: url("/Vendor/grotasans/Latinotype - GrotaSansAltBook.otf") format("opentype"); }

@font-face {
  font-family: GrotaSans;
  src: url("/Vendor/grotasans/Latinotype - GrotaSansRd-ExtraBold.otf") format("opentype");
  font-weight: 700; }

/*#afd186*/
.breadcrumb {
  background-color: transparent;
  padding: 0 15px 8px 15px;
  max-width: 1140px;
  margin: auto; }
  .breadcrumb a {
    color: #555; }

@media only screen and (max-width: 1199px) {
  .breadcrumb {
    max-width: 960px !important; } }

@media only screen and (max-width: 991px) {
  .breadcrumb {
    max-width: 720px !important;
    padding-top: 8px; } }

@media only screen and (max-width: 767px) {
  .breadcrumb {
    max-width: 540px !important;
    padding: 8px 15px 8px 15px; } }

.two-column .row {
  margin: 0; }

.two-column .link-list {
  padding: 0 4%; }

.two-column .text-area {
  width: 90%; }
  .two-column .text-area .row {
    margin: 0; }

.link-list {
  border: 0;
  list-style: none;
  width: 100%;
  padding-left: 0; }
  .link-list > li {
    padding: 20px 0;
    border-bottom: 1px solid #DCDCE5; }
    .link-list > li::before {
      border: 0; }
    .link-list > li:last-child {
      border-bottom: 0; }
  .link-list h2 {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 8px;
    padding-left: 24px; }
    .link-list h2::before {
      content: "";
      height: 16px;
      width: 16px;
      background: url(/Static/icons/AH/arrow-right-orange.svg) no-repeat center;
      background-size: contain;
      position: absolute;
      left: 0;
      top: 4px; }
  .link-list a {
    display: flex;
    flex-direction: column;
    position: relative;
    color: #00234A;
    font-size: 20px;
    font-weight: 500;
    line-height: 25px; }
  .link-list p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0; }

.newslist-heading {
  text-align: center; }

.newslift-link {
  display: block;
  background-color: #FFF;
  border-radius: 6px;
  box-shadow: 2px 2px 14px 0 rgba(0, 35, 74, 0.1);
  height: 100%;
  transition: 0.2s; }
  .newslift-link:hover, .newslift-link:focus {
    text-decoration: none;
    box-shadow: 2px 2px 14px 0 rgba(0, 35, 74, 0.3); }
  .newslift-link .img-area {
    position: relative;
    height: 0;
    padding-top: 56.25%;
    margin-bottom: 8px;
    border-radius: 6px 6px 0 0; }
    .newslift-link .img-area img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 6px 6px 0 0; }
  .newslift-link .date {
    color: #555;
    font-size: 15px;
    margin-top: 20px;
    margin-bottom: 16px; }
  .newslift-link .date, .newslift-link .feed-item-title, .newslift-link p {
    margin: 4px 20px; }
  .newslift-link .feed-item-title {
    line-height: 24px;
    font-size: 20px; }
  .newslift-link .arrow-link {
    color: #00234A;
    font-size: 16px;
    margin: 0 0 20px 16px; }
  .newslift-link p {
    font-size: 16px;
    line-height: 1.3em;
    margin-top: 15px;
    margin-bottom: 15px; }

.show-more.btn {
  background-color: #00234A;
  color: #FFAA64; }

.pagelistblock > .row > div[class^="col"] {
  margin-bottom: 24px; }

.link-lift {
  color: #00234A;
  display: block;
  background: #FFF;
  border-radius: 6px;
  box-shadow: 4px 2px 4px rgba(0, 0, 0, 0.3);
  height: 100%; }
  .link-lift:hover {
    text-decoration: none; }
  .link-lift .img-area {
    background-size: cover;
    background-position: center;
    border-radius: 6px 6px 0 0;
    height: 0;
    padding-top: 56.25%; }
  .link-lift .text-area {
    display: flex;
    flex-direction: column;
    padding: 20px; }
    .link-lift .text-area .title {
      font-size: 20px;
      font-weight: 500;
      line-height: 25px; }

@media only screen and (max-width: 767px) {
  .pagelistblock > .row > div[class^="col-md"] {
    padding: 0; } }

.icon-list {
  list-style: none;
  padding-left: 0; }
  .icon-list .icon-list-element {
    border-bottom: 1px dotted #DCDCE5;
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
    padding-bottom: 8px;
    padding-left: 0;
    padding-right: 0; }
    .icon-list .icon-list-element .icon {
      height: 56px; }
      .icon-list .icon-list-element .icon svg {
        height: 56px;
        width: 56px;
        stroke: #FFAA64;
        stroke-width: 1px; }
    .icon-list .icon-list-element .text-area {
      display: flex;
      flex-direction: column;
      margin-left: 8px; }
      .icon-list .icon-list-element .text-area .text {
        font-size: 16px; }
      .icon-list .icon-list-element .text-area .number {
        font-size: 24px;
        font-weight: 700; }

.hero-lift-container .hero-content .text-area.text-background .hero-heading {
  margin-bottom: 28px; }

.hero-lift-container .hero-content .text-area.text-background h1, .hero-lift-container .hero-content .text-area.text-background p {
  background-color: #FFF;
  display: inline; }

.hero-lift-container .hero-content .text-area.text-background .btn {
  margin-top: 16px; }

.hero-lift-container .hero-content .text-area.text-background.whitetext .hero-heading {
  margin-bottom: 28px; }

.hero-lift-container .hero-content .text-area.text-background.whitetext h1, .hero-lift-container .hero-content .text-area.text-background.whitetext p {
  background-color: #00234A;
  display: inline; }

.hero-lift-container .hero-content .text-area.text-background.whitetext .btn {
  margin-top: 16px; }

.hero-lift-container .hero-content .text-area h1, .hero-lift-container .hero-content .text-area p {
  color: #00234A; }

.hero-lift-container .hero-content .text-area .hero-link-button {
  background-color: transparent !important; }

.hero-lift-container .hero-content .text-area.whitetext h1 {
  color: #FFF !important; }

.hero-lift-container .hero-content .text-area.whitetext p {
  color: #FFF !important; }

.hero-lift-container .hero-content .text-area.whitetext span {
  color: #FFF !important;
  text-shadow: 1px 1px rgba(0, 35, 74, 0.2); }

.sub-hero-area {
  background-repeat: no-repeat;
  background-position: 68% 50%;
  background-size: contain;
  margin-bottom: 62px !important; }
  .sub-hero-area .container {
    height: 100%; }
    .sub-hero-area .container .row {
      height: 658px;
      margin-bottom: 0; }
      .sub-hero-area .container .row .col-md-6 {
        display: flex;
        align-items: center; }
  .sub-hero-area .text-area h1, .sub-hero-area .text-area p {
    color: #00234A;
    display: inline-block; }
  .sub-hero-area .text-area h1 {
    padding: 0 4px 8px 4px; }
  .sub-hero-area .text-area p {
    padding: 8px 4px 8px 4px; }
  .sub-hero-area .text-area.text-background .hero-heading {
    margin-bottom: 28px; }
  .sub-hero-area .text-area.text-background h1, .sub-hero-area .text-area.text-background p {
    background-color: #FFF;
    display: inline;
    box-shadow: .3em 0 0 #FFF, -.3em 0 0 #FFF;
    padding-left: 0; }
  .sub-hero-area .text-area.text-background h1 {
    line-height: 76px; }
  .sub-hero-area .text-area.text-background .btn {
    margin-top: 16px; }
  .sub-hero-area .text-area.text-background.whitetext .hero-heading {
    margin-bottom: 28px; }
  .sub-hero-area .text-area.text-background.whitetext h1, .sub-hero-area .text-area.text-background.whitetext p {
    background-color: #00234A;
    display: inline;
    box-shadow: 0.3em 0 0 #00234A, -0.3em 0 0 #00234A;
    padding-left: 0; }
  .sub-hero-area .text-area.text-background.whitetext h1 {
    line-height: 76px; }
  .sub-hero-area .text-area.text-background.whitetext .btn {
    margin-top: 16px; }
  .sub-hero-area .text-area.whitetext h1, .sub-hero-area .text-area.whitetext p {
    color: #FFF !important;
    text-shadow: 1px 1px rgba(0, 35, 74, 0.2); }
  .sub-hero-area .text-area .hero-link-button {
    background-color: transparent !important;
    padding: 0 !important; }
  .sub-hero-area .text-area span {
    background-color: #FFF;
    padding: 8px; }

.sub-hero-image {
  background-size: contain;
  background-position: 50% 50%;
  position: absolute;
  width: 72%;
  height: 100%;
  right: 0; }

@media only screen and (max-width: 991px) {
  .sub-hero-area {
    height: 576px; } }

@media only screen and (max-width: 767px) {
  .sub-hero-area {
    background-image: none !important;
    height: inherit;
    margin-bottom: 24px !important; }
    .sub-hero-area .text-area.text-background h1, .sub-hero-area .text-area h1 {
      line-height: 42px; }
    .sub-hero-area .herobgimage {
      width: 100%; }
    .sub-hero-area .container .row {
      height: inherit; }
  .sub-hero-image {
    display: none; } }

.news-hero-area.row {
  margin: 0; }

.main-content {
  margin-bottom: 24px; }

@media only screen and (max-width: 767px) {
  .main-content {
    margin-bottom: 12px; } }

/*
.content-lift-area {
    background-color: $ah-blue-background;
}
*/
.social-media {
  border-top: 1px solid #DCDCE5;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 40px;
  padding-top: 24px; }
  .social-media span {
    padding: 6px 8px 6px 6px; }
  .social-media a {
    display: block;
    padding: 6px;
    width: auto !important; }

.contact-page .information-area .ingress {
  margin-bottom: 16px; }
  .contact-page .information-area .ingress.title {
    font-size: 32px; }

.contact-page .social-media {
  margin: 0;
  padding-top: 12px;
  justify-content: flex-start; }
  .contact-page .social-media span {
    padding: 0; }
  .contact-page .social-media img {
    padding-bottom: 4px;
    max-width: 24px; }

.lightgallery a {
  position: relative;
  display: block;
  width: 100%;
  height: 0;
  padding-top: 56.25%; }
  .lightgallery a .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 35, 74, 0.2);
    transition: 0.2s;
    z-index: 1; }
  .lightgallery a:hover .overlay {
    background-color: transparent; }
  .lightgallery a::after {
    position: absolute;
    display: flex;
    height: 52px;
    width: 120px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #00234A url(/Static/icons/AH/play-circle-orange.svg) no-repeat 16px 50%;
    border-radius: 52px;
    color: #FFAA64;
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
    align-items: center;
    padding-left: 46px;
    z-index: 2; }
  .lightgallery a img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.lg-backdrop.in {
  opacity: 0.8 !important; }

.lg-rotate-right,
.lg-rotate-left,
.lg-flip-hor,
.lg-flip-ver,
#lg-share,
#lg-actual-size,
#lg-zoom-in,
#lg-zoom-out,
.lg-fullscreen {
  display: none !important; }

span.gallery-cookie-message {
  font-size: 18px;
  font-weight: 700;
  color: red; }

@media only screen and (max-width: 767px) {
  .lightgallery a::after {
    height: 44px;
    width: 108px;
    font-size: 16px;
    background-position: 14px 50%;
    padding-left: 42px; } }

.return-link {
  color: #555;
  font-size: 16px;
  line-height: 20px; }

.simplegallery .content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 360px;
  margin-bottom: 32px; }
  .simplegallery .content img {
    max-height: 100%; }

.simplegallery .thumbnail {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap; }
  .simplegallery .thumbnail .thumb {
    border-radius: 4px;
    margin: 0 4px;
    width: 84px;
    height: 84px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; }
    .simplegallery .thumbnail .thumb::after {
      border-radius: 4px;
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      z-index: 1;
      transition: 0.2s; }
    .simplegallery .thumbnail .thumb:hover::after {
      background-color: rgba(0, 0, 0, 0.05); }
    .simplegallery .thumbnail .thumb a {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%; }
    .simplegallery .thumbnail .thumb img {
      max-height: 90%;
      max-width: 90%; }

.product-page .content-area {
  margin-bottom: 24px; }
  .product-page .content-area .ingress {
    font-size: 24px;
    margin-bottom: 24px; }

.product-page .categories {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 16px; }
  .product-page .categories img {
    width: 80px;
    height: auto; }

.product-page.aptusstyle .arrow-link::before {
  background-image: url(/Static/icons/AH/arrow-right-aptus.svg) !important; }

.product-page.aptusstyle .btn-dark {
  background-color: #7FBF57;
  color: #000;
  font-weight: 600; }
  .product-page.aptusstyle .btn-dark:hover {
    background: #00234A;
    color: #7FBF57; }

.aptusstyle {
  color: #000; }
  .aptusstyle table {
    color: #000; }
  .aptusstyle.blue-background {
    background-color: #e3f1da; }
    .aptusstyle.blue-background table tr:nth-of-type(odd) {
      background-color: rgba(0, 0, 0, 0.1); }
  .aptusstyle h1, .aptusstyle h2 {
    font-family: "GrotaSans", sans-serif !important;
    font-weight: 700;
    color: #000; }
  .aptusstyle ul li::before {
    border-color: #7FBF57; }
  .aptusstyle .accordion-list .accordion-btn {
    color: #000;
    font-weight: 700; }
  .aptusstyle .accordion-list svg {
    color: #000 !important; }

@media only screen and (max-width: 991px) {
  .product-page .content-area {
    margin-top: 32px; }
    .product-page .content-area p, .product-page .content-area ul, .product-page .content-area ol, .product-page .content-area div {
      font-size: 16px;
      line-height: 24px; }
    .product-page .content-area .ingress {
      font-size: 18px; } }

@media only screen and (max-width: 767px) {
  .product-page .content-area {
    margin-top: 0; }
  .simplegallery {
    margin-bottom: 24px;
    margin-top: 24px; }
    .simplegallery .content {
      height: 280px; } }

.media-text {
  width: 80%;
  text-align: center; }

.embed-media-text {
  max-width: 80%;
  display: block; }

.social-media-buttons {
  text-align: right; }
  .social-media-buttons .btn {
    background-color: transparent; }
    .social-media-buttons .btn:hover {
      background-color: transparent; }

.accordion-list {
  list-style: none;
  padding-left: 0; }
  .accordion-list .accordion-item {
    border-bottom: 1px solid #DCDCE5; }
    .accordion-list .accordion-item:first-child {
      border-top: 1px solid #DCDCE5; }
    .accordion-list .accordion-item::before {
      border: 0; }
  .accordion-list .accordion-btn {
    background: transparent;
    border: 0;
    color: #00234A;
    height: 62px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    padding: 0 8px;
    cursor: pointer; }
    .accordion-list .accordion-btn.open .accordion-close {
      display: block; }
    .accordion-list .accordion-btn.open .accordion-open {
      display: none; }
  .accordion-list .accordion-content {
    display: none;
    padding: 0 8px; }
    .accordion-list .accordion-content p {
      font-size: 18px;
      line-height: 32px; }
  .accordion-list .accordion-icon {
    height: 24px;
    width: 24px; }
  .accordion-list .accordion-close {
    display: none; }
  .accordion-list svg {
    color: #FFAA64; }

.numscroller-row .col-md-6 {
  margin-bottom: 16px; }

.numscroller-row .infobox {
  border-bottom: 1px dotted #DCDCE5;
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;
  height: 100%; }
  .numscroller-row .infobox .img-area {
    margin-right: 16px; }
  .numscroller-row .infobox .numscroller-title {
    font-size: 16px;
    line-height: 23px; }
  .numscroller-row .infobox .value {
    font-size: 24px;
    font-weight: 600;
    line-height: 24px; }

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.9); }

.popup-box {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
  background-color: #FFF;
  border-radius: 8px;
  box-shadow: 2px 2px 14px 0 rgba(0, 35, 74, 0.1);
  padding: 16px;
  max-width: 648px !important; }

@media only screen and (min-width: 992px) {
  .popup-box {
    max-width: 768px !important; }
  .login-page .main-title-area {
    margin: auto; } }

.information-table .table-row {
  display: flex;
  flex-direction: row; }
  .information-table .table-row .title {
    font-weight: 600;
    min-width: 240px; }

@media only screen and (max-width: 767px) {
  .information-table .table-row {
    flex-direction: column;
    margin-bottom: 12px; } }

.contentleftrightblock {
  padding: 0 !important; }
  .contentleftrightblock .text-area, .contentleftrightblock .link-list {
    width: 90%; }

@media only screen and (max-width: 767px) {
  .contentleftrightblock .text-area, .contentleftrightblock .link-list {
    width: 100%;
    margin: 12px 0; }
  .contentleftrightblock .link-list {
    padding: 0; }
  .two-column .text-area {
    margin: 12px 0;
    width: 100%; } }

.materialpage, .ahproductpage {
  margin-bottom: 24px; }
  .materialpage .ah-product-card, .ahproductpage .ah-product-card {
    display: flex;
    flex-direction: column;
    font-family: "Rubik", sans-serif;
    box-shadow: 2px 2px 14px 0 rgba(0, 35, 74, 0.1);
    border-radius: 5px;
    height: 100%;
    justify-content: space-between;
    margin-bottom: 24px;
    padding-top: 20px; }
  .materialpage .ah-card-header, .ahproductpage .ah-card-header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 200px; }
    .materialpage .ah-card-header a, .ahproductpage .ah-card-header a {
      display: flex;
      height: 100%;
      width: 100%;
      align-items: flex-end;
      justify-content: center; }
    .materialpage .ah-card-header .ah-card-header-image, .ahproductpage .ah-card-header .ah-card-header-image {
      max-height: calc(100% - 16px); }
  .materialpage .ah-product-card-body, .ahproductpage .ah-product-card-body {
    display: flex;
    flex-direction: column;
    padding: 16px 20px; }
  .materialpage .ah-product-card-category, .ahproductpage .ah-product-card-category {
    color: #555;
    margin-bottom: 7.5px;
    font-size: 15px; }
  .materialpage .ah-product-card-heading, .ahproductpage .ah-product-card-heading {
    color: #00234A;
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.5; }
  .materialpage .ah-product-card-excerpt, .materialpage .ah-product-card-excerpt p, .materialpage .ah-product-card-excerpt ul, .ahproductpage .ah-product-card-excerpt, .ahproductpage .ah-product-card-excerpt p, .ahproductpage .ah-product-card-excerpt ul {
    color: #000;
    font-size: 16px; }
  .materialpage .ah-product-card-bottom, .ahproductpage .ah-product-card-bottom {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-end;
    padding: 16px 20px; }
    .materialpage .ah-product-card-bottom a, .ahproductpage .ah-product-card-bottom a {
      background-size: 20px !important;
      display: block;
      font-size: 16px;
      margin-right: 16px;
      padding-left: 24px; }
      .materialpage .ah-product-card-bottom a.download-pdf, .ahproductpage .ah-product-card-bottom a.download-pdf {
        background: url(/Static/icons/AH/download-orange.svg) no-repeat left center; }
      .materialpage .ah-product-card-bottom a.order, .ahproductpage .ah-product-card-bottom a.order {
        background: url(/Static/icons/AH/plus-square-orange.svg) no-repeat left center; }
  .materialpage .ah-card-species-icons, .ahproductpage .ah-card-species-icons {
    position: absolute;
    top: 0;
    right: 15px;
    display: flex;
    background-color: white;
    border-bottom-left-radius: 5px;
    padding: 10px;
    border: 1px solid #DCDCE5;
    border-top: none;
    border-right: none;
    z-index: 1; }
  .materialpage .ah-card-species-icon, .ahproductpage .ah-card-species-icon {
    max-height: 28px;
    max-width: 28px;
    margin: 0 2px; }
  .materialpage .ah-animal-symbol, .ahproductpage .ah-animal-symbol {
    max-width: 24px;
    max-height: 24px;
    width: 24px; }

.floatingbuttonarea > div {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  width: fit-content;
  padding: 0; }
  .floatingbuttonarea > div .floatingbuttonblock {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0; }
  .floatingbuttonarea > div .floating-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10000;
    background-color: #91C8FF;
    margin-bottom: 10px;
    padding: 12px 16px 12px;
    text-align: center;
    width: 180px;
    border-radius: 6px 0 0 6px;
    transition: 0.2s;
    box-shadow: -4px 4px 6px rgba(0, 0, 0, 0.2);
    margin-bottom: 24px; }
    .floatingbuttonarea > div .floating-button.blue {
      background-color: #91C8FF; }
    .floatingbuttonarea > div .floating-button.orange {
      background-color: #FFAA64; }
    .floatingbuttonarea > div .floating-button:hover, .floatingbuttonarea > div .floating-button:focus {
      width: 188px;
      color: #00234A; }
    .floatingbuttonarea > div .floating-button img {
      max-width: 32px; }
    .floatingbuttonarea > div .floating-button span {
      width: 148px; }

.modal .row {
  margin: 0 0 2px; }

.modal div[class*="col"] {
  padding: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 100%; }

.modal form .Form__Element .Form__Element__Caption {
  display: block; }

.modal form .Form__Element {
  margin: 0 0 1rem 0; }

.modal form input[type=text], .modal form textarea {
  width: 100% !important; }

.modal form input[type="submit"] {
  background: #00234A;
  color: #FFAA64;
  transition: 0.2s;
  border: 0;
  border-radius: 4px;
  font-weight: 600;
  padding: 8px 16px;
  cursor: pointer; }
  .modal form input[type="submit"]:hover, .modal form input[type="submit"]:focus {
    background: #FA811D;
    color: #00234A; }

.modal form .list-order, .modal form .single-checkbox-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4px; }
  .modal form .list-order label, .modal form .single-checkbox-row label {
    margin-bottom: 0;
    margin-left: 4px; }

@media only screen and (max-width: 991px) {
  .floatingbuttonblock .floating-button {
    width: 120px !important;
    bottom: 20%;
    padding: 6px 8px 6px !important; }
    .floatingbuttonblock .floating-button:hover, .floatingbuttonblock .floating-button:focus {
      width: 124px; }
    .floatingbuttonblock .floating-button span {
      font-size: 14px;
      max-width: 112px; } }

@media only screen and (max-width: 767px) {
  .floatingbuttonarea > div {
    top: unset;
    bottom: 0;
    transform: inherit;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end; }
    .floatingbuttonarea > div .floatingbuttonblock {
      flex-basis: fit-content; }
      .floatingbuttonarea > div .floatingbuttonblock .floating-button {
        margin-bottom: 4px;
        padding: 4px !important; }
      .floatingbuttonarea > div .floatingbuttonblock:first-child {
        align-items: flex-start; }
        .floatingbuttonarea > div .floatingbuttonblock:first-child .floating-button {
          border-radius: 0 6px 6px 0; } }

@font-face {
  font-family: GrotaSans;
  src: url("/Vendor/grotasans/Latinotype - GrotaSansAltBook.otf") format("opentype"); }

@font-face {
  font-family: GrotaSans;
  src: url("/Vendor/grotasans/Latinotype - GrotaSansRd-ExtraBold.otf") format("opentype");
  font-weight: 700; }

/*#afd186*/
.searchboxform {
  margin-bottom: 20px; }
  .searchboxform .input-group {
    position: relative; }
  .searchboxform .sb-search-input {
    height: 40px;
    padding: 4px 8px;
    width: 100%; }
  .searchboxform input[type="text"] {
    max-width: 100%; }
  .searchboxform .input-group-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%); }
    .searchboxform .input-group-btn button {
      border: 0;
      width: 32px;
      height: 32px;
      background: url(/Static/icons/feather/search.svg) no-repeat center; }

.search-field {
  background-color: #FFF !important;
  top: 100px !important; }

.search-nav {
  display: flex;
  justify-content: center;
  align-items: center; }
  .search-nav .toggle-search {
    margin-top: 14px;
    position: relative; }
    .search-nav .toggle-search svg {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%); }

.form-nav, #search-form {
  position: relative; }
  .form-nav .sb-search-input, #search-form .sb-search-input {
    border: 1px solid #AEAEBA;
    border-radius: 40px;
    height: 40px;
    padding: 8px 24px 8px 12px;
    width: 100%;
    max-width: 100%; }
  .form-nav .sb-search-submit, #search-form .sb-search-submit {
    position: absolute;
    right: 8px;
    border: 0;
    width: 32px;
    height: 32px;
    background: url(/Static/icons/feather/search.svg) no-repeat center; }

@media only screen and (max-width: 991px) {
  .search-h1 {
    margin-top: 32px; }
  header.main-header .search-field {
    top: 64px !important; } }

.search-filter {
  display: none; }

.search-no-results {
  padding: 0 15px; }

.sb-search-submit {
  cursor: pointer; }

.search-item {
  border-bottom: 1px solid #AEAEBA;
  padding: 24px 0; }
  .search-item .search-item-title {
    display: block;
    font-size: 20px;
    margin-bottom: 4px; }
  .search-item p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 4px; }

.pagination {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 24px; }
  .pagination li {
    margin: 0 4px; }
    .pagination li a {
      display: flex;
      justify-content: center;
      min-width: 20px;
      padding: 2px; }
      .pagination li a:hover {
        background-color: #F2F2F2; }
    .pagination li::before {
      display: none;
      border: 0; }
    .pagination li.current {
      color: #999; }
      .pagination li.current a:hover {
        background-color: transparent; }

body {
  background-image: none !important;
  color: #00234A;
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  /*
    a[target="_blank"] {
        position: relative;
        width: inherit;

        &::after {
            position: absolute;
            content: "";
            right: -4px;
            top: 50%;
            transform: translate(0, -50%);
            height: 20px;
            width: 20px;
            background: url(/Static/icons/external.svg) no-repeat center;
        }
    }
        */ }
  body #page-body {
    margin-top: 100px; }
    body #page-body.start {
      margin-top: 0; }
  body .container-hd {
    width: 100%;
    max-width: 1920px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto; }
  body .row {
    margin-top: 40px;
    margin-bottom: 40px; }
    body .row .row {
      margin-top: 0; }
  body .white > * {
    background-color: #FFF !important; }
  body .blue-c > * {
    background-color: #99B9D9; }
  body .blue-d > * {
    background-color: #E0F3FE; }
  body .green-c > * {
    background-color: #9DD9E3 !important; }
  body .green-d > * {
    background-color: #D8F0F4 !important; }
  body .yellow-c > * {
    background-color: #FFE292 !important; }
  body .yellow-d > * {
    background-color: #FFEFC3 !important; }
  body .grey-c > * {
    background-color: #ACB4BD !important; }
  body .grey-d > * {
    background-color: #E0E8F2 !important; }
  body .blue-background {
    background-color: #F0F7FF; }
  body p {
    font-family: "Rubik", sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px; }
    body p a {
      color: #003D99;
      text-decoration: underline; }
      body p a:hover {
        text-decoration: none; }
  body strong {
    font-weight: 500; }
  body a.btn {
    text-decoration: none; }
  body .ingress {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    margin-bottom: 40px; }
  body blockquote.ingress {
    border-left: 5px solid #FFAA64;
    display: block;
    font-family: "Amaranth", sans-serif;
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
    padding: 8px 0 12px 16px; }
  body h1, body .h1 {
    color: #00234A;
    font-family: "Amaranth", sans-serif;
    font-size: 60px;
    font-weight: 700;
    line-height: 72px;
    margin-bottom: 28px; }
  body h2, body .h2 {
    color: #00234A;
    font-size: 34px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 24px; }
  body h3, body .h3 {
    color: #00234A;
    font-size: 26px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 20px; }
  body .row {
    margin-bottom: 24px; }
  body img {
    max-width: 100%;
    height: auto; }
  body a {
    color: #00234A; }
    body a:hover {
      text-decoration: underline; }
  body form a {
    color: #003D99;
    text-decoration: underline; }
    body form a:hover {
      text-decoration: none; }
  body ul {
    list-style: none;
    position: relative;
    font-size: 18px; }
    body ul li {
      position: relative;
      margin-bottom: 6px; }
      body ul li::before {
        position: absolute;
        left: -24px;
        top: 6px;
        content: "";
        height: 8px;
        width: 16px;
        border-left: 2px solid #FA811D;
        border-bottom: 2px solid #FA811D;
        transform: rotate(-45deg); }
  body ol li::before {
    border: 0; }
  body #epi-quickNavigator li::before {
    border: 0; }
  body .btn, body button {
    background-color: #FFAA64;
    border: 0;
    border-radius: 0.25rem;
    color: #00234A;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    padding: 13px 18px;
    text-shadow: none;
    cursor: pointer; }
    body .btn:hover, body button:hover {
      background-color: #FA811D;
      color: #00234A; }
    body .btn.btn-dark, body button.btn-dark {
      background-color: #00234A;
      color: #FFAA64; }
      body .btn.btn-dark:hover, body .btn.btn-dark:focus, body button.btn-dark:hover, body button.btn-dark:focus {
        background-color: #FA811D;
        color: #00234A; }
  body .ctabutton a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: #00234A;
    border: 0;
    border-radius: 0.25rem;
    color: #FFAA64;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    padding: 13px 18px;
    text-align: center;
    text-shadow: none;
    text-decoration: none !important;
    transition: 0.1s; }
    body .ctabutton a:hover, body .ctabutton a:focus {
      background-color: #FA811D;
      color: #00234A; }
  body .bluelinkbutton a, body .orangelinkbutton a {
    display: block;
    border: 0;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    padding: 13px 18px;
    text-align: center;
    text-shadow: none;
    text-decoration: none !important;
    transition: 0.1s;
    width: 240px; }
  body .bluelinkbutton a {
    background-color: #00234A;
    color: #FFAA64; }
    body .bluelinkbutton a:hover, body .bluelinkbutton a:focus {
      background-color: #FA811D;
      color: #00234A; }
  body .orangelinkbutton a {
    background-color: #FFAA64;
    color: #00234A; }
    body .orangelinkbutton a:hover, body .orangelinkbutton a:focus {
      background-color: #FA811D;
      color: #00234A; }
  body .arrow-link, body .hero-lift-container .right a {
    position: relative;
    display: inline-block;
    padding-left: 24px;
    /*
        &:hover, &:focus {
            &:before {
                left: 8px;
            }
        }*/ }
    body .arrow-link:before, body .hero-lift-container .right a:before {
      position: absolute;
      content: "";
      width: 23px;
      height: 16px;
      background: url(/Static/icons/AH/arrow-right-orange.svg) no-repeat center;
      background-size: contain;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      transition: 0.2s; }
  body table {
    border: 0;
    color: #00234A;
    margin-bottom: 16px; }
    body table tr {
      height: 40px !important;
      transition: 0.1s; }
      body table tr:nth-of-type(odd) {
        background-color: #f2f2f2; }
    body table th, body table td {
      border: 0;
      font-size: 20px;
      padding-left: 8px; }
    body table th {
      font-size: 20px;
      font-weight: 700; }

video {
  max-width: 100%; }

#page-body {
  background-image: none !important; }

.col-lg-2.displaymode-one-fifth {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%; }

@media only screen and (max-width: 991px) {
  body {
    font-size: 16px; }
    body #page-body {
      margin-top: 64px; }
    body h1, body .h1 {
      font-size: 32px;
      line-height: 30px; }
    body h2, body .h2 {
      font-size: 28px;
      line-height: 32px; }
    body p, body ul, body ol {
      font-size: 16px;
      line-height: 24px; }
    body ul {
      padding-left: 24px; }
  .col-lg-2.displaymode-one-fifth {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33%;
    flex: 0 0 33%;
    max-width: 33%; } }

@media only screen and (max-width: 767px) {
  .col-lg-2.displaymode-one-fifth {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; } }

@media only screen and (max-width: 575px) {
  .col-lg-2.displaymode-one-fifth {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

/*
.ui-blur {
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background-color: $ah-blue !important;
    opacity: 1;
}
    */
.ui-widget-overlay.ui-front {
  display: none !important;
  background-color: #00234A !important;
  opacity: 1; }

.language-popup-dialog.ui-dialog .ui-dialog-titlebar {
  padding-left: 24px; }
  .language-popup-dialog.ui-dialog .ui-dialog-titlebar .ui-dialog-title {
    color: #00234A !important;
    font-weight: 700; }

.ui-dialog {
  border: 1px solid #00234A;
  border-radius: 0.25rem;
  z-index: 1000 !important;
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  margin-right: -50% !important;
  transform: translate(-50%, -50%) !important;
  background-color: #FFF;
  box-shadow: 2px 2px 14px 0 rgba(0, 35, 74, 0.1);
  padding: 4px; }
  .ui-dialog .ui-dialog-titlebar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #00234A;
    color: #FFF;
    padding: 4px 4px 4px 10px; }
  .ui-dialog .ui-dialog-content {
    padding: 8px; }
  .ui-dialog button {
    background-color: #FFAA64;
    border: 0;
    border-radius: 0.25rem;
    color: #00234A;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 4px;
    padding: 13px 18px;
    text-shadow: none;
    cursor: pointer; }
    .ui-dialog button:hover {
      background-color: #FA811D;
      color: #00234A; }
    .ui-dialog button.ui-dialog-titlebar-close {
      background: #FFAA64 url(/Static/icons/AH/open.svg) no-repeat center;
      background-size: 100%; }
  .ui-dialog.language-dialog {
    box-shadow: 2px 2px 14px 0 rgba(0, 35, 74, 0.1); }
    .ui-dialog.language-dialog p, .ui-dialog.language-dialog a {
      color: #00234A;
      font-size: 18px; }
    .ui-dialog.language-dialog .ingress {
      margin-bottom: 8px; }
    .ui-dialog.language-dialog ul {
      margin-bottom: 32px; }
    .ui-dialog.language-dialog button {
      background-color: #FFF; }
      .ui-dialog.language-dialog button.ui-dialog-titlebar-close {
        background: #FFF url(/Static/icons/feather/x-circle.svg) no-repeat center;
        height: 24px;
        width: 24px; }
    .ui-dialog.language-dialog .ui-dialog-titlebar {
      background-color: #FFF;
      border: 0; }

@media only screen and (max-width: 767px) {
  .ui-dialog {
    top: 0 !important;
    transform: translate(-50%, 0) !important;
    overflow-y: auto;
    max-height: 100vh; }
  .btn {
    white-space: inherit; }
  .left {
    margin-bottom: 16px; } }

@media only screen and (max-width: 575px) {
  .ui-dialog {
    width: 312px !important; } }

.aptus-page h1, .aptus-page h2, .aptus-page h3, .aptus-page h4 {
  font-family: "GrotaSans", sans-serif !important;
  font-weight: 700;
  color: #000; }

.aptus-page p, .aptus-page li {
  color: #000; }

.aptus-page .btn, .aptus-page button {
  background: #7FBF57;
  color: #FFF; }

.aptus-page > .container > .row {
  margin-top: 8px; }

.aptus-page .hero-lift-container {
  border-top: 6px solid #7FBF57;
  height: 60vh; }
  .aptus-page .hero-lift-container .hero-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-bottom: 0; }
    .aptus-page .hero-lift-container .hero-content .text-area {
      text-align: center; }

.aptus-page .breadcrumb {
  margin-top: 24px; }

.aptus-page .navbar.aptus-navbar {
  background-color: #7FBF57;
  color: #FFF !important;
  font-family: "GrotaSans", sans-serif;
  font-weight: 700;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap; }
  .aptus-page .navbar.aptus-navbar nav .menu {
    display: flex !important;
    padding: 0;
    list-style: none;
    max-width: 1140px;
    margin: auto;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; }
    .aptus-page .navbar.aptus-navbar nav .menu li {
      padding: 0 8px;
      text-align: center; }
      .aptus-page .navbar.aptus-navbar nav .menu li a {
        color: #FFF;
        font-size: 28px; }
      .aptus-page .navbar.aptus-navbar nav .menu li.current a {
        text-decoration: underline; }
        .aptus-page .navbar.aptus-navbar nav .menu li.current a:hover {
          text-decoration: none; }
      .aptus-page .navbar.aptus-navbar nav .menu li::before {
        display: none; }
      .aptus-page .navbar.aptus-navbar nav .menu li ul {
        display: none !important; }
  .aptus-page .navbar.aptus-navbar .navbar-nav {
    max-width: 1140px;
    margin: auto;
    flex-direction: row;
    flex-wrap: wrap; }
    .aptus-page .navbar.aptus-navbar .navbar-nav .nav-item {
      font-size: 28px;
      padding: 0 8px; }
      .aptus-page .navbar.aptus-navbar .navbar-nav .nav-item .nav-link {
        color: #FFF; }
      .aptus-page .navbar.aptus-navbar .navbar-nav .nav-item::before {
        display: none; }
  .aptus-page .navbar.aptus-navbar img {
    height: 120px; }
  .aptus-page .navbar.aptus-navbar .aptus-logo > img {
    height: 90px; }

.aptus-page .ctabutton a, .aptus-page .bluelinkbutton a {
  background-color: #7FBF57;
  color: #FFF; }

.aptus-page .blue-background {
  background-color: #e3f1da !important; }

.aptus-page .aptus-footer {
  background-color: #7FBF57;
  padding: 8px 16px; }
  .aptus-page .aptus-footer img {
    height: 120px; }
  .aptus-page .aptus-footer .aptus-logo > img {
    height: 90px; }

body .btn.product-category-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px !important;
  color: #FFF;
  font-size: 16px !important;
  margin-bottom: 16px;
  position: relative;
  transition: 0.2s; }
  body .btn.product-category-btn span {
    z-index: 1; }
  body .btn.product-category-btn::before {
    border-radius: 8px;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0; }
    body .btn.product-category-btn::before:hover, body .btn.product-category-btn::before:focus {
      background-color: rgba(0, 0, 0, 0.1); }
  body .btn.product-category-btn:hover, body .btn.product-category-btn:focus {
    color: #FFF;
    box-shadow: 2px 2px 14px 0 rgba(0, 35, 74, 0.1); }
    body .btn.product-category-btn:hover::before, body .btn.product-category-btn:focus::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.2);
      left: 0;
      top: 0; }
  body .btn.product-category-btn.joint {
    background-color: #00a551 !important; }
  body .btn.product-category-btn.gastro {
    background-color: #ec7a08 !important; }
  body .btn.product-category-btn.skin {
    background-color: #bf2296 !important; }
  body .btn.product-category-btn.eyes {
    background-color: #7577c0 !important; }
  body .btn.product-category-btn.dental {
    background-color: #6aade4 !important; }
  body .btn.product-category-btn.health {
    background-color: #005293 !important; }
  body .btn.product-category-btn.behavior {
    background-color: #00b2a9 !important; }
  body .btn.product-category-btn.aptus-red {
    background-color: #d20c30 !important; }
  body .btn.product-category-btn.aptus-yellow {
    background-color: #fecb00 !important; }
  body .btn.product-category-btn.selected {
    background-color: #FFF !important;
    color: #00234A !important; }
    body .btn.product-category-btn.selected:hover {
      color: #00234A !important; }

.animal-category-btn {
  box-shadow: 2px 2px 14px 0 rgba(0, 35, 74, 0.1);
  border-radius: 4px;
  margin-bottom: 16px;
  padding: 4px;
  transition: 0.2s; }
  .animal-category-btn:hover, .animal-category-btn:focus {
    box-shadow: 2px 2px 14px 0 rgba(0, 35, 74, 0.3); }

@media only screen and (max-width: 1300px) {
  .aptus-page .navbar.aptus-navbar img {
    height: 80px !important;
    width: auto; } }

@media only screen and (max-width: 991px) {
  .aptus-page .navbar.aptus-navbar {
    justify-content: space-between; }
    .aptus-page .navbar.aptus-navbar nav .menu {
      flex-direction: column;
      margin: 8px 0; }
      .aptus-page .navbar.aptus-navbar nav .menu li {
        padding: 8px 8px; }
    .aptus-page .navbar.aptus-navbar .navbar-nav {
      flex-direction: column; }
      .aptus-page .navbar.aptus-navbar .navbar-nav .nav-item {
        padding: 8px 8px; } }

@media only screen and (max-width: 767px) {
  .aptus-page .navbar.aptus-navbar {
    justify-content: center; }
    .aptus-page .navbar.aptus-navbar img {
      display: none; } }
